@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;

  .modalContent {
    padding: 20px 40px 20px 40px;
    width: 500px;
    //height: 600px;
    height: auto;

    background-color: $card-color-background;
    border-radius: 25px;

    .logoBox {
      width: 100%;

      .logo {
        display: block;
        width: auto;
        margin: 0 auto;
      }
    }

    .title {
      @include bold-font-style(36px);
      color: $color-main-blue;

      text-align: center;
      padding-top: 40px;
    }

    .inputs {
      padding-top: 30px;

      .input {
        display: block;
        width: 100%;
        margin-top: 30px;
        height: 30px;
        outline: none;
        border: none;

        border-bottom: 1px solid #c3c3c3;
        background-color: $card-color-background;

        @include regular-font-style(18px);
        transition: 0.5s;
      }

      .input:focus {
        border-bottom: 1px solid $color-main-blue;
      }
    }

    .personalDataAgree {
      padding-top: 30px;
      display: flex;
      align-items: center;

      .input {
        -webkit-appearance: checkbox;
      }

      .text {
        @include regular-font-style(16px);
        line-height: 1.4;
        padding-left: 10px;

        .agreementLink {
          color: $color-main-blue;
        }

        .agreementLink:hover {
          cursor: pointer;
          border-bottom: 1px solid $color-main-blue;
        }
      }
    }

    .errorMessage {
      @include regular-font-style(16px);

      text-align: center;
      align-content: center;

      margin-top: 10px;
      width: 100%;
      height: auto;
      border-radius: 25px;

      padding: 5px 0 5px 0;

      background-color: #ff5c5c;
    }

    .successMessage {
      @include regular-font-style(14px);

      text-align: center;
      align-content: center;

      margin-top: 10px;
      width: 100%;
      height: auto;
      border-radius: 25px;

      background-color: #3dff46;

      padding: 5px 0 5px 0;
    }

    .btnBox {
      .btn {
        display: block;
        width: auto;
        margin: 25px auto;
        outline: none;
        border: none;
        background-color: $color-main-blue;
        color: #fff;
        padding: 15px 70px 15px 70px;
        border-radius: 25px;

        @include regular-font-style(16px);
        transition: .5s;
      }

      .btn:hover {
        cursor: pointer;
        background-color: #1286e3;
      }
    }
  }
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 500px) {
  .modal {
    .modalContent {
      width: 95%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-height: 600px) {
  .modal {
    .modalContent {
      height: 95%;
    }
  }
}

@media screen and (max-height: 570px) {
  .modal {
    .modalContent {
      height: auto;

      .title {
        font-size: 28px;
      }

      .inputs {
        padding-top: 10px;
      }

      .personalDataAgree {
        .text {
          font-size: 12px;
        }
      }
    }
  }
}