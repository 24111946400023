@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.partnersBlock {
  padding-bottom: 50px;

  .partnerSlide {
    padding-top: 50px;

    .columns {
      display: flex;
      justify-content: space-between;

      .textColumn {
        width: 60%;

        .textWrapper {
          .companyName {
            @include bold-font-style(36px);
            color: $title-color-gray;

            padding-bottom: 20px;
          }

          .reviewText {
            @include regular-font-style(18px);
            line-height: 1.5;
            color: $text-color-gray;
          }
        }
      }

      .playerColumn {
        width: 35%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .partnersBlock {
    padding-bottom: 0;
    .partnerSlide {
      padding-top: 0 !important;

      .columns {
        display: block !important;

        .playerColumn {
          width: 70%;
          margin: 0 auto;
        }

        .textColumn {
          padding-top: 25px;
          width: 100%;

          .textWrapper {
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}