@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.inDevelopmentPage {
  .errorMessage {
    @include bold-font-style(65px);
    width: 100%;
    text-align: center;
    margin-top: 100px;

    color: $color-main-blue;
  }

  .inDevMode {
    @include bold-font-style(44px);
    width: 100%;
    text-align: center;
    margin-top: 70px;
    line-height: 1.4;

    color: $title-color-gray;
  }

  .contactUs, .phone {
    @include regular-font-style(24px);
    text-align: center;
    margin-top: 50px;

    line-height: 1.6;

    color: $text-color-gray;
  }

  .phone {
    color: $color-main-blue;
    font-size: 36px;
    border-bottom: 1px solid $color-main-blue;
    text-align: center;

    margin-top: 15px;
  }
}