@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.footer {
  padding-top: 100px;
  border-top: 1px solid #c3c3c3;

  .columns {
    display: flex;
    justify-content: space-between;

    .column {
      width: 19%;

      .columnTitle {
        @include bold-font-style(16px);
        color: $title-color-gray;
      }

      .columnItems {
        padding-top: 15px;

        .columnItem {
          display: block;
          margin-top: 5px;

          @include regular-font-style(14px);
          color: $text-color-dark;
        }
      }
    }
  }


  .contactsColumns {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;

    .contactColumn {
      width: 32%;

      .addresses {
        .address {
          @include regular-font-style(14px);
          padding-top: 5px;
          color: $title-color-gray;
        }
      }

      .phoneNumber {
        height: 100%;
        align-content: center;
        text-align: right;
        @include regular-font-style(14px);
        color: $title-color-gray;
      }

      .socials {
        display: flex;
        align-content: center;
        height: 100%;


        .social {
          display: block;
          width: 42px;
          align-content: center;
          margin-left: 15px;

          .socialIcon {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .remark {
    margin-top: 30px;
    .companyName {
      @include regular-font-style(16px);
      color: $title-color-gray;

      padding-bottom: 15px;
    }

    .remarkText {
      padding-top: 15px;
      padding-bottom: 10px;

      @include regular-font-style(16px);
      line-height: 1.3;

      color: $title-color-gray;
      word-wrap: break-word;

      text-align: center;

      border-top: 1px solid #c3c3c3;
    }
  }
}

@media screen and (max-width: 650px) {
  .footer {
    padding-top: 60px;

    .columns {
      display: block;

      .column {
        width: 100%;
        text-align: center;

        margin-top: 25px;
      }
    }

    .contactsColumns {
      display: block;
      padding-top: 30px;

      .contactColumn {
        width: 100%;
        margin-top: 25px;
        text-align: center;

        .phoneNumber {
          text-align: center;
        }

        .socials {
          justify-content: space-around;
        }
      }
    }

    .remark {
      text-align: center;

      .remarkText {
        font-size: 14px;
      }
    }
  }
}