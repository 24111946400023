@import "../../assets/styles/variables";

section {
  padding-top: 100px;
}

.container {
  width: $container-xl;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}

@media screen and (max-width: 400px) {
  section {
    padding-top: 50px;
  }
}
