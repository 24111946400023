@import 'variables';

@mixin bold-font-style($font-size) {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: $font-size;
}

@mixin regular-font-style($font-size) {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: $font-size;
}

@mixin card-dark-shadow {
  box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);
}

@mixin card-blue-shadow {
  box-shadow: 1px 3px 19px -10px $color-main-blue;
}