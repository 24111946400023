@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.mainButton {
  border-radius: 40px;

  background-color: $color-main-blue;
  outline: none;
  border: none;

  display: flex;
  width: 260px;
  height: 65px;

  .buttonText {
    @include regular-font-style(20px);
    color: #fff;
    height: 100%;
    width: 100%;
    text-align: left;
    align-content: center;
  }

  .icon {
    width: 65px;
    height: 65px;
  }
}

.mainButton:hover {
  transition: 0.5s all;
  background-color: #1286e3;
  cursor: pointer;
}


@media screen and (max-width: 500px) {
  .mainButton {
    width: 90%;
    margin: 0 auto;

    .buttonText {
      text-align: center;
    }
  }
}
