@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";


.sectionTitle {
  @include bold-font-style($section-title-xl);
  color: $color-main-blue;
  padding-bottom: 50px;

  text-align: left;
}

@media screen and (max-width: $container-l) {
  .sectionTitle {
    font-size: $section-title-l;
  }

}

@media screen and (max-width: $container-m) {
  .sectionTitle {
    font-size: $section-title-m;
  }
}


@media screen and (max-width: $container-s) {
  .sectionTitle {
    text-align: center;
    font-size: $section-title-s;
  }
}