@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.joinUsBlock {
  width: 100%;
  background-color: $card-color-background;
  border-radius: 20px;

  .content {
    width: 90%;
    margin: 0 auto;

    padding-top: 50px;
    padding-bottom: 50px;

    .columns {
      display: flex;
      justify-content: space-between;

      .column {
        width: 48%;
        height: 100%;

        .textWrapper {
          height: 100%;
          align-content: center;

          .title {
            @include bold-font-style(38px);
            color: $title-color-gray;
            line-height: 1.6;
            padding-bottom: 50px;
            word-break: break-word;
          }

          .text {
            @include regular-font-style(16px);
            color: $text-color-gray;
            line-height: 1.6;

            padding-bottom: 50px;
          }
        }

        .imageItem {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .joinUsBlock {

    .content {
      .columns {
        display: block;

        .column {
          width: 80%;
          margin: 0 auto;

          .imageItem {
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .joinUsBlock {

    .content {
      .columns {
        display: block;

        .column {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .joinUsBlock {
    .title {
      font-size: 26px !important;
      text-align: left;
    }
  }
}