@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";


.hide {
  display: none;
}

header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .headerWrapper {
    width: 70%;
    max-width: 1400px;
    height: 60px;
    margin: 20px auto 0;

    display: flex;
    justify-content: space-between;

    border-radius: 25px;
    box-shadow: 1px 3px 19px -10px $color-main-blue;
    background-color: $card-color-background;


    a {
      color: $title-color-dark;
      @include regular-font-style(18px);

      align-content: center;

      .logo {
        padding-left: 20px;
        width: 180px;
        height: 35px;
      }
    }

    nav {
      align-content: center;

      .navItems {
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          padding-right: 20px;
        }
      }
    }

    .headerContacts {
      display: block;
      padding-right: 20px;
      align-content: center;

      a {
        display: block;
      }
    }


    // burger start

    .burgerIcon {
      padding-right: 20px;
      display: none;
      color: $color-main-blue;
    }

    .burgerIcon:hover {
      cursor: pointer;
      color: $color-main-blue;
      transition: all 0.5s;
    }

    .hideIcon {
      display: none;
    }
  }

  .burger {
    background-color: #fff;

    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    animation: anim .5s forwards;

    .displayCloseIcon {
      align-content: center;
      display: block;
      color: $color-main-blue;

      position: absolute;
      right: 0;
      top: 30px;
      padding-right: 30px;
    }

    .burgerNav {
      display: block !important;
      height: 100%;
      align-content: center;

      .navItems {
        li {
          padding-bottom: 20px;

          a {
            display: block;
            width: 100%;
            color: $color-main-blue;
            @include regular-font-style(20px);
            text-align: center;
          }
        }
      }
    }
  }

  @keyframes anim {
    0% {
      transform: translateX(150%)
    }
    100% {
      transform: translateX(0)
    }
  }
}

@media screen and (max-width: 1500px) {
  .headerWrapper {
    width: 80% !important;
  }
}

@media screen and (max-width: 1200px) {
  .headerWrapper {
    width: 90% !important;
  }
}

@media screen and (max-width: 900px) {
  .burgerIcon {
    display: block !important;
    align-content: center;
  }

  nav {
    display: none !important;
  }

  .headerContacts {
    display: flex !important;

    a:not(:last-child) {
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 600px) {
  .headerContacts {
    display: none !important;
  }
}