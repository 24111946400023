@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";


.directions {
  .direction {
    margin-top: 50px;

    .columns {
      display: flex;
      justify-content: space-between;

      .column {
        margin-top: 20px;
        width: 32%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .directions {
    .direction {
      .columns {
        display: block;
        width: 60%;
        margin: 0 auto;

        .column {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .directions {
    .direction {
      .columns {
        width: 100%;
      }
    }
  }
}