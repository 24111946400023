  @import "../../assets/styles/variables";
@import "../../assets/styles/mixins";


.firstSection {
  padding-top: 150px;
}

.container {
  width: $container-xl;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}
