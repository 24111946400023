// variables
$color-site-background: #f1f1f3;
$color-site-background-dark: #E2F2FF;

$color-main-blue: #0C94FF;
$color-main-orange: #f87045;
$color-main-green: #29b571;

$text-color-gray: #AFAFAF;
$text-color-dark: #57729A;
$title-color-gray: #656565;
$title-color-dark: #193661;

$card-color-background: #f6f6f8;
$card-color-background-dark: #C3E1F9;
$card-color-border: #a8a8a8;

// breakpoints
$container-xl: 1200px;
$container-l: 900px;
$container-m: 600px;
$container-s: 400px;

// adaptive
$section-title-xl: 54px;
$section-title-l: 44px;
$section-title-m: 28px;
$section-title-s: 26px;