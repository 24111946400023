@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.directionCard {
  width: 100%;
  height: 325px;

  background: $card-color-background;
  border-radius: 25px;
  @include card-blue-shadow;

  transition: .5s;

  .cardContent {
    padding: 20px 20px 20px 20px;


    .icons {
      display: flex;
      justify-content: space-between;
      width: 50x;
      height: 50px;
    }

    .textWrapper {
      padding-top: 50px;
      @media screen and (max-width: 400px) {
        padding-top: 30px;
      }

      .title {
        @include bold-font-style(30px);
        color: $title-color-gray;

        @media screen and (max-width: 400px) {
          word-break: break-word;
        }
      }

      .text {
        @include regular-font-style(16px);
        color: $title-color-dark;
        line-height: 1.4;
        padding-top: 20px;
      }
    }
  }
}

.directionCard:hover {
  cursor: pointer;
  transform: translateX(15px) translateY(-15px);
}